export const i18nStrings = {
  overflowMenuTitleText: "Menu",
  overflowMenuTriggerText: "More",
};

export const profileActions = [
  {
    type: "menu-dropdown",
    id: "support-group",
    text: "Support",
    items: [
      {
        id: "whats-new",
        text: "What's New",
        href: "https://quip-amazon.com/s4l5AeIdtpyd/Gaia-Whats-New-",
        external: true,
        externalIconAriaLabel: " (opens in new tab)",
      },
      {
        id: "product-roadmap",
        text: "Product Roadmap",
        href: "https://quip-amazon.com/4AMuAoZYaL85#temp:C:MSb2EEF2u0dnh91RK8TQGg",
        external: true,
        externalIconAriaLabel: " (opens in new tab)",
      },
      {
        id: "wiki",
        text: "Wiki",
        href: "https://w.amazon.com/bin/view/FinanceTechnology/Gaia/",
        external: true,
        externalIconAriaLabel: " (opens in new tab)",
      },
      {
        id: "feature-request",
        text: "Feature Request",
        href: "https://issues.amazon.com/issues/create?template=f1b35942-a3d9-4c1b-bd98-f7b6665efa4f",
        external: true,
        externalIconAriaLabel: " (opens in new tab)",
      },
      {
        id: "report-bug",
        text: "Report Bug",
        href: "https://t.corp.amazon.com/create/templates/082f27fb-9033-440a-861c-34c9d034f3a1",
        external: true,
        externalIconAriaLabel: " (opens in new tab)",
      },
    ],
  },
];
