import { mainApi } from "services/main";
import {
  IGetTemplateResponse,
  IGetTemplateRequest,
  IListTemplatesResponse,
  IListTemplatesRequest,
} from "./definitions";

export const extendedApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getTemplate: builder.query<IGetTemplateResponse, IGetTemplateRequest>({
      query: (request: IGetTemplateRequest) => ({
        url: `templates/${request.templateName}/${request.teamName}`,
      }),
    }),
    listTemplates: builder.query<IListTemplatesResponse, IListTemplatesRequest>(
      {
        query: (request: IListTemplatesRequest) => ({
          url: "templates",
          method: "GET",
          params: request,
        }),
      }
    ),
  }),
  overrideExisting: false,
});

export const { useGetTemplateQuery, useListTemplatesQuery, usePrefetch } =
  extendedApi;
