import React from "react";

import { useTranslation } from "react-i18next";

import { useDocumentTitle } from "hooks";

import { useBreadcrumbs } from "features/breadcrumbs";
import { useInfo } from "features/info";
import { usePageHeader } from "features/page-header";

import { IPageProps } from "./definitions";
import { useAppSelector } from "app/store";

const Page: React.FC<IPageProps> = ({
  type,
  children,
  className,
  hasBreadcrumbs,
  title,
  description,
  hasInfo,
  help,
  actions,
  overlapWithHeader = true,
}) => {
  const { t } = useTranslation();
  const appName = t("app_name");

  const updatePageTitle = useDocumentTitle();
  const { updateBreadcrumbs } = useBreadcrumbs();
  const { updatePageHeader } = usePageHeader();
  const { updateInfo } = useInfo();
  const requireContentLayout = type === "page" || type === "tabs";
  const selectedTask: any = useAppSelector(
    (state) => state.audits.selectedTask
  );

  const extractTextFromJSX = (element: React.ReactNode): string => {
    if (React.isValidElement(element)) {
      const children = element.props.children;
      if (Array.isArray(children)) {
        return children[0];
      }
    }
    return String(element);
  };

  React.useEffect(() => {
    const titleText = extractTextFromJSX(title);
    updatePageTitle(titleText);
    updateBreadcrumbs(
      hasBreadcrumbs
        ? [
            { text: appName, href: "/" },
            { text: titleText, href: "#" },
          ]
        : []
    );
    updatePageHeader({
      title,
      description,
      hasInfo,
      help,
      actions,
      requireContentLayout,
    });
    updateInfo(help);
  }, [title, selectedTask.milestone]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section className={`page ${className ?? ""}`} data-testid="page">
      {!overlapWithHeader && (
        /* anyone have any better ideas? */
        <div>
          <br />
          <br />
        </div>
      )}
      {children}
    </section>
  );
};

export default Page;
