import { ITemplatesTableProps } from "./definitions";
import { createLabelFunction } from "utils/table-utils";
import { SpaceBetween, TableProps } from "@amzn/awsui-components-react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { Header, Link, Table } from "@amzn/awsui-components-react";
import {
  IListTemplatesRequest,
  IGetTemplateRequest,
  TaskTemplate,
  TaskGroupTemplate,
  TemplateTableItem,
} from "./definitions";
import { useListTemplatesQuery, extendedApi } from "./endpoints";
import { useTeamNameSelector } from "../../pages/team_settings/hooks";

const TemplatesTable: React.FC<ITemplatesTableProps> = (props) => {
  const { teamName } = useTeamNameSelector();
  const request: IListTemplatesRequest = {
    teamName: teamName as string,
  };

  const { data } = useListTemplatesQuery(request, {
    refetchOnMountOrArgChange: true,
  });

  const [getTemplate] = extendedApi.endpoints.getTemplate.useLazyQuery();

  const convertToCSV = (data: TaskGroupTemplate[]) => {
    if (!data || data.length === 0) {
      return "";
    }

    const excludeFields = ["order", "tasks"];
    const groupHeaders = Object.keys(data[0])
      .filter((key) => !excludeFields.includes(key))
      .map((key) => key.charAt(0).toUpperCase() + key.slice(1));

    const firstGroupWithTasks = data.find(
      (group) => group.tasks && group.tasks.length > 0
    );
    const taskHeaders = firstGroupWithTasks
      ? Object.keys(firstGroupWithTasks.tasks[0])
          .filter((key) => !excludeFields.includes(key))
          .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
      : [];

    let csvRows = [];

    const displayGroupHeaders = groupHeaders.map((header) =>
      header.toLowerCase() === "name" ? "Task Group" : header
    );
    const displayTaskHeaders = taskHeaders.map((header) =>
      header.toLowerCase() === "name" ? "Task" : header
    );

    csvRows.push([...displayGroupHeaders, ...displayTaskHeaders].join(","));

    data.forEach((group: TaskGroupTemplate) => {
      const groupValues = groupHeaders.map((header) => {
        const value =
          group[
            header.toLowerCase() as keyof Omit<TaskGroupTemplate, "tasks">
          ] ?? "";
        return `"${value}"`;
      });
      const emptyTaskFields = Array(taskHeaders.length).fill('""');
      csvRows.push([...groupValues, ...emptyTaskFields].join(","));

      if (group.tasks && group.tasks.length > 0) {
        group.tasks.forEach((task: TaskTemplate) => {
          const emptyGroupFields = Array(groupHeaders.length).fill('""');
          const taskValues = taskHeaders.map((header) => {
            const key = header.toLowerCase();
            const taskKey = Object.keys(task).find(
              (k) => k.toLowerCase() === key
            );
            const value = taskKey ? task[taskKey as keyof TaskTemplate] : "";
            return `"${value}"`;
          });
          csvRows.push([...emptyGroupFields, ...taskValues].join(","));
        });
      }
    });

    return csvRows.join("\n");
  };

  const downloadTemplate = async (item: any) => {
    const getTemplateRequest: IGetTemplateRequest = {
      teamName: teamName,
      templateName: item.templateName.replace(/\//g, "_").replace(/\s+/g, "_"),
    };

    const templateData = await getTemplate(getTemplateRequest);
    const parsedTemplate = JSON.parse(templateData?.data?.template || "");
    const csvContent = convertToCSV(parsedTemplate);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${getTemplateRequest.templateName}.csv`);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    window.URL.revokeObjectURL(url);
  };

  const rawColumnDefinitions: TableProps<TemplateTableItem>["columnDefinitions"] =
    [
      {
        id: "templateName",
        header: "Name",
        cell: (item) => item.templateName,
        ariaLabel: createLabelFunction("Title"),
        sortingField: "name",
      },
      {
        id: "templateId",
        header: "Type",
        cell: (item) => item.category,
        ariaLabel: createLabelFunction("Category"),
        sortingField: "type",
      },
      {
        id: "details",
        header: "Details",
        cell: (item) => (
          <Link onFollow={() => downloadTemplate(item)}>Info</Link>
        ),
        ariaLabel: createLabelFunction("Description"),
        sortingField: "details",
      },
    ];

  const templates = data?.templates?.templateList ?? [];

  const { items } = useCollection(templates, {
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: "title",
        },
      },
    },
  });
  return (
    <div>
      <SpaceBetween size="l">
        <Table
          header={
            <div style={{ marginBottom: "10px" }}>
              <Header>Templates</Header>
            </div>
          }
          columnDefinitions={rawColumnDefinitions}
          resizableColumns={true}
          items={items}
          variant="container"
        ></Table>
      </SpaceBetween>
    </div>
  );
};

export default TemplatesTable;
